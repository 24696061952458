// import React, { Component } from 'react';
// import axios from 'axios';
// class Contact extends Component {
//    constructor(props) {
//       super(props);
//       this.state = {
//          contactName: '',
//          contactEmail: '',
//          contactMessage: ''
//       }
//    }
//    onNameChange(event) {
//       this.setState({ name: event.target.value })
//    }
//    onEmailChange(event) {
//       this.setState({ email: event.target.value })
//    }
//    onMsgChange(event) {
//       this.setState({ message: event.target.value })
//    }
//    submitEmail(e) {
//       e.preventDefault();
//       axios({
//          method: "POST",
//          url: "/send",
//          data: this.state
//       }).then((response) => {
//          if (response.data.status === 'success') {
//             alert("Message Sent.");
//             this.resetForm()
//          } else if (response.data.status === 'fail') {
//             alert("Message failed to send.")
//          }
//       })
//    }
//    resetForm() {
//       this.setState({ contactName: '', contactEmail: '', contactMessage: '' })
//    }
//    render() {

//       if (this.props.data) {
//          var name = this.props.data.name;
//          var street = this.props.data.address.street;
//          var city = this.props.data.address.city;
//          var state = this.props.data.address.state;
//          var zip = this.props.data.address.zip;
//          var phone = this.props.data.phone;
//          var email = this.props.data.email;
//          var message = this.props.data.contactmessage;
//       }

//       return (
//          <section id="contact">

//             <div className="row section-head">

//                <div className="two columns header-col">

//                   <h1><span>Get In Touch.</span></h1>

//                </div>

//                <div className="ten columns">

//                   <p className="lead">{message}</p>

//                </div>

//             </div>

//             <div className="row">
//                <div className="eight columns">

//                   <form action="" method="post" id="contactForm" name="contactForm">
//                      <fieldset>

//                         <div>
//                            <label htmlFor="contactName">Name <span className="required">*</span></label>
//                            <input type="text" defaultValue="" size="35" id="contactName" name="contactName" value={this.state.contactName} onChange={this.onNameChange.bind(this)} />
//                         </div>

//                         <div>
//                            <label htmlFor="contactEmail">Email <span className="required">*</span></label>
//                            <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" value={this.state.contactEmail} onChange={this.onEmailChange.bind(this)} />
//                         </div>

//                         {/* <div>
//                            <label htmlFor="contactSubject">Subject</label>
//                            <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" value={this.state.contactSubject} onChange={this.onSubjectChange.bind(this)} />
//                         </div> */}

//                         <div>
//                            <label htmlFor="contactMessage">Message <span className="required">*</span></label>
//                            <textarea cols="50" rows="15" id="contactMessage" name="contactMessage" value={this.state.contactMessage} onChange={this.onMsgChange.bind(this)}></textarea>
//                         </div>

//                         <div>
//                            <button className="submit">Submit</button>
//                            <span id="image-loader">
//                               <img alt="" src="images/loader.gif" />
//                            </span>
//                         </div>
//                      </fieldset>
//                   </form>

//                   <div id="message-warning"> Error boy</div>
//                   <div id="message-success">
//                      <i className="fa fa-check"></i>Your message was sent, thank you!<br />
//                   </div>
//                </div>


//                <aside className="four columns footer-widgets">
//                   <div className="widget widget_contact">

//                      <h4>Address</h4>
//                      <p className="address">
//                         {name}<br />
//                         {street} <br />
//                         {city}, {state} {zip}<br />
//                         {/* <span>{phone}</span> */}
//                      </p>
//                   </div>

//                   <div className="widget widget_tweets">
//                      <h4 className="widget-title">Contact me</h4>
//                      <ul id="twitter">
//                         <li>
//                            <span>
//                               for further question, please send me anything that you want to ask about it
//                         {/* <a href="#">http://t.co/CGIrdxIlI3</a> */}
//                            </span>
//                            {/* <b><a href="#">2 Days Ago</a></b> */}
//                         </li>
//                         <li>
//                            <span>
//                               This section will have update soon related to add  AI chatbot
//                         {/* <a href="#">http://t.co/CGIrdxIlI3</a> */}
//                            </span>
//                            {/* <b><a href="#">3 Days Ago</a></b> */}
//                         </li>
//                      </ul>
//                   </div>
//                </aside>
//             </div>
//          </section>
//       );
//    }
// }

// export default Contact;
import React, { useState } from 'react';
import { db } from '../firebase.js';
import { useForm } from 'react-hook-form';
import validator from 'validator';
const Contact = () => {
   const { register, errors } = useForm();
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [message, setMessage] = useState("");
   const [loader, setLoader] = useState(false);
   const [nameError, setNameErr] = useState({});
   const [emailError, setEmailErr] = useState({});

   const [messageError, setMseeageErr] = useState({});

   // const [emailError] = "";

   const handleSubmit = (e) => {
      e.preventDefault();
      const isValid = formValidation();
      if (isValid) {
         setLoader(true)
         db.collection('contacts').add({
            name: name,
            email: email,
            message: message,
         })
            .then(() => {
               alert("Message has been submitted")
               setLoader(false)
            })
            .catch(error => {
               alert(error.message)
               setLoader(false)
            });
         setName('');
         setEmail('');
         setMessage('')
      }

   }
   const formValidation = () => {
      const nameError = {};
      const emailError = {};
      const messageError = {}
      let isValid = true;

      if (name == "") {
         nameError.emptyName = "You must write your name";
         isValid = false
      }
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {

         isValid = true;
      }
      else {

         isValid = false;
         emailError.emptyName = "Please enter valid email address.";

      }
      if (message == "") {
         messageError.emptyMessage = "you must write your message";
         isValid = false;
      }
      setNameErr(nameError);
      setEmailErr(emailError);
      setMseeageErr(messageError);
      return isValid;

   }

   return (
      <section id="contact">
         <form className="contactForm" onSubmit={handleSubmit}>
            <div className="Innercontact">
               <h1>Get in touch</h1>
               <p> if you are looking for other details that not included in the website,<br />
                  I would be happy to clear any confusion, but first  I will need your email to answer your question as soon as possiable!</p>
               <label>Name</label>
               <input placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
               {Object.keys(nameError).map((key) => {
                  return <div style={{ color: "#d11919", fontSize: 14, fontWeight: "bold" }}>{nameError[key]}</div>
               })}
               <label>Email</label>
               <input placeholder="Enter your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
               {Object.keys(emailError).map((key) => {
                  return <div style={{ color: "#d11919", fontSize: 14, fontWeight: "bold" }}>{emailError[key]}</div>
               })}
               <label>Message</label>
               <textarea placeholder="write your Message here" value={message} onChange={(e) => setMessage(e.target.value)} />
               {Object.keys(messageError).map((key) => {
                  return <div style={{ color: "#d11919", fontSize: 14, fontWeight: "bold", }}>{messageError[key]}</div>
               })}
               <button type="submit" style={{ background: loader ? "#ccc" : "#423444" }}>Submit</button>
            </div>
            <div className="AIchatBot"> <h2>AI chatbot coming SOON!<br /> for this section</h2></div>
         </form>
      </section>
   )
}

export default Contact;

import firebase from 'firebase';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyAeoNJCqPWFbLq2I4KoIxvY8CWD-wQ07qg",
    authDomain: "reyam-portfolio-49db8.firebaseapp.com",
    databaseURL: "https://reyam-portfolio-49db8.firebaseio.com",
    projectId: "reyam-portfolio-49db8",
    storageBucket: "reyam-portfolio-49db8.appspot.com",
    messagingSenderId: "578558465240",
    appId: "1:578558465240:web:371fc36029bdd90bb2f3ad",
    measurementId: "G-09RDK6TB5P"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

var db = firebase.firestore();
export {db};
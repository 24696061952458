import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return <div key={education.school}><h3>{education.school}</h3>
          <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
          <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function (work) {
        return <div key={work.company}><h3>{work.company}</h3>
          <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
          <p>{work.description}</p>
        </div>
      })
      var skills = this.props.data.skills.map(function (skills) {
        var skillsImage = 'images/' + skills.image;
        return <div key={skills.title} className="columns portfolio-item">
          <div className="item-wrap">

            <img src={skillsImage} />
            <h1>{skills.title}</h1>
            <div>


              <p>{skills.describtion}</p>
            </div>
            {/* <div className="link-icon"><i className="fa fa-link"></i></div> */}

          </div>
        </div>



      })
    }

    return (
      <section id="resume">
        <section id="education">
          <div className="row education">
            <div className="three columns header-col">
              <h1><span>Education</span></h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  {education}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="work">
          <div className="row work">

            <div className="three columns header-col">
              <h1><span>Work</span></h1>
            </div>

            <div className="nine columns main-col">
              {work}
            </div>
          </div>

        </section>

        <section id="skills">
          <div className="rowskill">
            <h1><span>Skills</span></h1>
            <p>the skills divided into groups related to the field</p>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {skills}
            </div>
            {/* <div className="Skills">

          </div> */}

            {/* <div className="percentage-col">
            <p>AI Skills</p>
            <div className="bars">
              <ul className="skills"> */}
            {/* {AIskills} */}
            {/* </ul>
            </div>
          </div> */}
          </div>
        </section>
      </section >
    );
  }
}

export default Resume;
